<script lang="ts">
	import JobsBannerVideo from "./JobsBannerVideo.svelte";
	import { getTranslate } from "../../utils/getTranslate.js";
	import translations from "./Jobs.translations.json";
	import development from "../../assets/videos/brano-hp-career-research-development.mp4";
	import production from "../../assets/videos/brano-hp-career-production.mp4";
	import office from "../../assets/videos/brano-hp-career-office.mp4";
	import management from "../../assets/videos/brano-hp-career-sales-management.mp4";
	import developmentPoster from "../../assets/videos/brano-hp-career-research-development.jpg?webp&h=260&imagetools";
	import productionPoster from "../../assets/videos/brano-hp-career-production.jpg?webp&h=260&imagetools";
	import officePoster from "../../assets/videos/brano-hp-career-office.jpg?webp&h=260&imagetools";
	import managementPoster from "../../assets/videos/brano-hp-career-sales-management.jpg?webp&h=260&imagetools";
	import { createEventDispatcher } from "svelte";
	import { CareerType } from "../../../../core/schema/CareerType.js";

	const translate = getTranslate(translations);
	const dispatch = createEventDispatcher<{
		click: CareerType;
	}>();

	const videos = [
		{
			type: CareerType.Development,
			video: development,
			poster: developmentPoster,
			title: translate("development"),
		},
		{
			type: CareerType.Production,
			video: production,
			poster: productionPoster,
			title: translate("production"),
		},
		{
			type: CareerType.Office,
			video: office,
			poster: officePoster,
			title: translate("office"),
		},
		{
			type: CareerType.SalesAndManagement,
			video: management,
			poster: managementPoster,
			title: translate("businessAndManagement"),
		},
	];
</script>

<div class="mt-8 grid gap-4 lg:mt-16 lg:grid-cols-2 lg:grid-rows-2">
	{#each videos as video}
		<a
			href="#offers"
			on:click={() => {
				dispatch("click", video.type);
			}}
		>
			<JobsBannerVideo {...video} />
		</a>
	{/each}
</div>
