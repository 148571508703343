<script lang="ts">
	import { flyInOnScroll } from "../../utils/flyInOnScroll.js";
	import StaticVideo from "../Video/StaticVideo.svelte";

	export let video: string;
	export let poster: string;
	export let title: string;
</script>

<div use:flyInOnScroll class="relative h-[16.063rem] overflow-hidden">
	<p
		class="md:text-2lg absolute bottom-5 left-5 z-20 m-0 text-[1.75rem] font-bold text-white sm:text-lg md:bottom-10 md:left-10"
	>
		{title}
	</p>
	<div class="absolute inset-0 z-10 bg-gradient-to-b from-[#0505051A] to-[#16161680]"></div>
	<StaticVideo {video} {poster} class="absolute top-1/2 -translate-y-1/2" />
</div>
